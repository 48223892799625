@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500&display=swap');



html {
  overflow: auto;
  scrollbar-gutter: stable;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Barlow', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  background: #131F2B;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::placeholder {
  color: #c0c0c0;
  opacity: 1; /* Firefox */
}

::-ms-input-placeholder { /* Edge 12 -18 */
  color: #c0c0c0;
}

textarea::-webkit-scrollbar {
  width: 0.5em;
}

textarea::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
}

textarea::-webkit-scrollbar-thumb {
background-color: rgba(255,255,255,0.2);
outline: 1px solid rgba(255,255,255,0.2);
}

.EmojiPickerReact.epr-dark-theme  {
  --epr-bg-color: #2d4154;
  --epr-category-label-bg-color: #2d4154;
  --epr-search-input-bg-color: #131E28;
  --epr-emoji-size: 20px;
  --epr-preview-height: 48px;
  --epr-emoji-padding:4px;
  --epr-category-navigation-button-size: 24px;
  --epr-header-padding: 6px 10px 0px;
  --epr-search-input-height: 34px;
  --epr-category-label-height: 34px;
}

.EmojiPickerReact h2 {
  font-size: 15px;
}

.epr-body::-webkit-scrollbar {
  width: 0.5em;
}

.epr-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
}

.epr-body::-webkit-scrollbar-thumb {
background-color: rgba(255,255,255,0.2);
outline: 1px solid rgba(255,255,255,0.2);
}

.tooltip {
  /* background-color: rgb(0, 247, 255) !important; */
  padding: 12px 16px !important;
  border-radius: 8px !important;
  max-width: 200px !important;

  font-family: 'Barlow' !important;
  font-size: 13px !important;
  font-weight: 400 !important;
}

.countrySelection::-webkit-scrollbar {
  width: 0.3em;

}

.countrySelection::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
}

.countrySelection::-webkit-scrollbar-thumb {
  background-color: rgba(255,255,255,0.2);
  outline: 1px solid rgba(255,255,255,0.2);
  border-radius: 0.15em;
}

#chatMessages::-webkit-scrollbar {
  width: 0.5em;

}

#chatMessages::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
}

#chatMessages::-webkit-scrollbar-thumb {
background-color: rgba(255,255,255,0.2);
outline: 1px solid rgba(255,255,255,0.2);
border-radius: 0.25em;
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background: #1c2a37;
  border-radius: 1px;
}

body::-webkit-scrollbar-thumb {

  background: #2d4154;
  border-radius: 5px;
}

.table-wrap::-webkit-scrollbar {
  height: 5px;
}

.table-wrap::-webkit-scrollbar-track {
  background: #1c2a37;
  border-radius: 1px;
}

.table-wrap::-webkit-scrollbar-thumb {

  background: #2d4154;
  border-radius: 5px;
}

.line-wrapper {
  overflow: hidden;
}

.intercom-lightweight-app-launcher {
  visibility: hidden;
}

.post-preview-modal::-webkit-scrollbar {
  width: 5px;
}

.post-preview-modal::-webkit-scrollbar-track {
  background: #1c2a37;
  border-radius: 1px;
}

.post-preview-modal::-webkit-scrollbar-thumb {
  background: #2d4154;
  border-radius: 5px;
}

.dropdown-selection::-webkit-scrollbar {
  width: 5px;
}

.dropdown-selection::-webkit-scrollbar-track {
  background: #1c2a37;
  border-radius: 1px;
}

.dropdown-selection::-webkit-scrollbar-thumb {
  background: #2d4154;
  border-radius: 5px;
}










